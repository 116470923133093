import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { Add } from "@mui/icons-material"
import { EffectItem, StateItem, Transition } from "../../../../types"
import StatemachineContext from "../../../../context/statemachine.context"

interface AddStateProps {
    onAdd: (item: StateItem) => void
}

export const AddState = ({ onAdd }: AddStateProps) => {
    const { alphabet, states, effects, globalTransitions, setGlobalTransitions } =
        useContext(StatemachineContext)

    const [id, setId] = useState("")
    const [transitions, setTransitions] = useState<Transition[]>([])
    const [selectedUserInput, setSelectedUserInput] = useState<string[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [isGlobal, setIsGlobal] = useState(false)
    const [effectValue, setEffectValue] = useState<EffectItem>({
        id: "",
        text: ["", "", "", "", ""],
        comment: "",
        shouldEndSession: false,
    })
    const [globalInput, setGlobalInput] = useState("")

    const handleDialog = () => {
        setOpen(!open)
    }

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value)
    }

    const handleChipChange = (e: SelectChangeEvent) => {
        // @ts-ignore
        setSelectedUserInput(e.target.value as string[])
    }

    const handleGlobalInputSelect = (e: SelectChangeEvent) => {
        setGlobalInput(e.target.value)
    }

    const handleSelectChange = (e: SelectChangeEvent) => {
        const effect = effects.filter((eff) => eff.id === e.target.value)?.[0]
        setEffectValue(effect)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        handleDialog()

        if (isGlobal) {
            setGlobalTransitions([
                ...globalTransitions,
                {
                    effect: effectValue.id,
                    source: "",
                    sourceHandle: globalInput,
                    targetHandle: "",
                    target: id,
                    alphabet: "",
                },
            ])
        }

        onAdd({ id, transitions, allowedAlphabet: selectedUserInput })
        setId("")
        setTransitions([])
    }

    return (
        <>
            <Button
                type="submit"
                startIcon={<Add />}
                onClick={handleDialog}
                id="add-state-button"
            >
                Hinzufügen
            </Button>
            <Dialog open={open} onClose={handleDialog}>
                <DialogTitle>State hinzufügen</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="ID"
                                    placeholder='Bsp.: "MENU" oder "HELP"'
                                    variant="standard"
                                    fullWidth
                                    required
                                    value={id}
                                    onChange={handleIdChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>
                                        Was für Nutzereingaben sind in dem Zustand
                                        erlaubt?
                                    </InputLabel>
                                    <Select
                                        id="userInput"
                                        multiple
                                        onChange={handleChipChange}
                                        fullWidth
                                        // @ts-ignore
                                        value={selectedUserInput}
                                    >
                                        {alphabet.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.id}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="global"
                                    onChange={() => setIsGlobal(!isGlobal)}
                                    control={<Checkbox />}
                                    label="Kann man in diesen State durch eine globale Alexa Eingabe gelangen? (Alexa Eingabe muss als global gekenzeichnet sein)"
                                />
                            </Grid>
                            {isGlobal && (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel>
                                                Erreichbar durch globale Eingabe
                                            </InputLabel>
                                            <Select
                                                id="globalInput"
                                                onChange={handleGlobalInputSelect}
                                                fullWidth
                                                // @ts-ignore
                                                value={globalInput}
                                            >
                                                {alphabet
                                                    .filter((i) => i.isGlobal)
                                                    .map((item) => (
                                                        <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.id}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel>Alexa Ausgabe</InputLabel>
                                            <Select
                                                id="userInput"
                                                onChange={handleSelectChange}
                                                fullWidth
                                                value={effectValue.id}
                                            >
                                                {effects.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.id}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit">Hinzufügen</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
