import axios from "axios"
import { AlphabetItem, EffectItem, StateItem } from "../types"

export const COGNITO_DOMAIN = "https://wdrvoice.auth.eu-central-1.amazoncognito.com"

export const LOGIN_URL =
    COGNITO_DOMAIN +
    "/oauth2/authorize?client_id=6u7uer69kcvsbsu6nbuf9kdd1&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fcloudservices.wdr.de/voice/hilbert"

const cognitoClient = axios.create({
    baseURL: COGNITO_DOMAIN,
})

const client = axios.create({
    baseURL: "https://sm8op2odih.execute-api.eu-central-1.amazonaws.com/dev",
})

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token")
        if (token) {
            // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error)
)

export async function authenticateUser(code: string) {
    const params = new URLSearchParams()
    params.append("grant_type", "authorization_code")
    params.append("client_id", "6u7uer69kcvsbsu6nbuf9kdd1")
    params.append("code", code)
    params.append("redirect_uri", "https://cloudservices.wdr.de/voice/hilbert")

    return cognitoClient
        .post("/oauth2/token", params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => res.data)
        .catch((err) => err.data)
}

export async function createSkill(
    name: string,
    invocation: string,
    tester: string[],
    states: StateItem[],
    alphabet: AlphabetItem[],
    effects: EffectItem[]
) {
    return client
        .post("/create", { name, invocation, tester, states, alphabet, effects })
        .then((res) => res.data)
        .catch((err) => err.data)
}
