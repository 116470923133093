import React from "react"
import { Handle, Position } from "react-flow-renderer"
import { Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            backgroundColor: "white",
            padding: 4,
            margin: 0,
            textAlign: "center",
            fontSize: 12,
            boxShadow: "",
        },
    }),
    { name: "CustomNode" }
)

export const CustomNode = ({ data }: { data: any }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div>
                <Typography>{data.label}</Typography>
            </div>
            <Handle
                type="target"
                id="input"
                position={Position.Top}
                style={{ width: 10, height: 10, top: -20 }}
            />
            <Handle
                type="source"
                id="fallback"
                position={Position.Top}
                style={{
                    backgroundColor: "red",
                    width: 10,
                    height: 10,
                    left: 0,
                    top: -20,
                }}
            />
            <Handle
                type="source"
                id="effectNavigation"
                position={Position.Left}
                style={{
                    backgroundColor: "orange",
                    width: 10,
                    height: 10,
                    left: -20,
                }}
            />
            {data.stateAlphabet.map((item: string, index: number) => (
                <Handle
                    type="source"
                    id={item}
                    position={Position.Bottom}
                    style={{
                        backgroundColor: "green",
                        width: 10,
                        height: 10,
                        bottom: -20,
                        color: "white",
                        fontSize: 18,
                        lineHeight: "40px",
                        left:
                            data.stateAlphabet.length === 1
                                ? "50%"
                                : `${(100 / data.stateAlphabet.length) * index}%`,
                    }}
                >
                    {item}
                </Handle>
            ))}
        </div>
    )
}
