import { Message } from "../../types"
import { Grid, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(
    (theme: Theme) => ({
        userMessage: {
            display: "flex",
            flexDirection: "row-reverse",
        },
        alexaMessage: {
            display: "flex",
        },
        user: {
            minWidth: 50,
            padding: theme.spacing(1),
            borderRadius: 7,
            backgroundColor: "rgba(100, 106, 114, 1)",
            color: "white",
            position: "relative",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 3,
                right: -6,
                color: "green",
                borderLeft: "10px solid rgba(100, 106, 114, 1)",
                borderTopColor: "inerhit",
                borderTop: "10px solid transparent",
                borderBottom: "10px solid transparent",
            },
        },
        alexa: {
            minWidth: 50,
            padding: theme.spacing(1),
            borderRadius: 7,
            backgroundColor: "#00b0e6",
            position: "relative",
            color: "white",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 3,
                left: -6,
                color: "green",
                borderRight: "10px solid #00b0e6",
                borderTopColor: "inerhit",
                borderTop: "10px solid transparent",
                borderBottom: "10px solid transparent",
            },
        },
    }),
    { name: "ChatContainer" }
)

interface ChatProps {
    messages: Message[]
}

export const Chat = ({ messages }: ChatProps) => {
    const classes = useStyles()

    return (
        <Grid container spacing={2}>
            {messages
                .filter((e) => !!e.text)
                .map((message) => (
                    <Grid item xs={12}>
                        <div
                            className={
                                message.type === 0
                                    ? classes.userMessage
                                    : classes.alexaMessage
                            }
                        >
                            <Typography
                                className={
                                    message.type === 0 ? classes.user : classes.alexa
                                }
                            >
                                {message.text}
                            </Typography>
                        </div>
                    </Grid>
                ))}
        </Grid>
    )
}
