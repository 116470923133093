import { Typography } from "@mui/material"
import { Handle, Position } from "react-flow-renderer"

export const EndNode = ({ data }: { data: any }) => {
    return (
        <div id="end-node">
            <div>
                <Typography>{data.label}</Typography>
            </div>
            <Handle
                type="target"
                id="input"
                position={Position.Top}
                style={{ width: 10, height: 10, top: -20 }}
            />
        </div>
    )
}
