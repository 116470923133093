import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main: "#00345e",
            dark: "#00345e",
        },
        secondary: {
            light: "#ff8800",
            main: "#ff8800",
            contrastText: "#000000",
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    boxShadow: "none",
                },
            },
        },
    },
})

export default theme
