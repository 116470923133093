import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material"
import React, { ReactHTMLElement, useContext, useState } from "react"
import { EffectItem, Transition } from "../../../../types"
import StatemachineContext from "../../../../context/statemachine.context"
import { Chat } from "../../../../components/Chat/ChatContainer"
import { getRandomAlphabetText } from "../../../../utils/strings"

interface NewTransitionProps {
    open: boolean
    onClose: () => void
    onSubmit: (transition: Transition) => void
    currentTransition: Transition
}

export const NewTransition = ({
    open,
    onClose,
    onSubmit,
    currentTransition,
}: NewTransitionProps) => {
    const { effects, states, alphabet } = useContext(StatemachineContext)
    const [effectValue, setEffectValue] = useState<EffectItem>({
        id: "",
        text: ["", "", "", "", ""],
        comment: "",
        shouldEndSession: false,
    })
    const [condition, setCondition] = useState("")

    const handleSubmit = () => {
        setCondition("")
        setEffectValue({
            id: "",
            text: ["", "", "", "", ""],
            comment: "",
            shouldEndSession: false,
        })
        onSubmit({ ...currentTransition, effect: effectValue.id, condition })
    }

    const handleConditionChange = (e: SelectChangeEvent) => {
        setCondition(e.target.value)
    }

    const handleSelectChange = (e: SelectChangeEvent) => {
        const effect = effects.filter((eff) => eff.id === e.target.value)?.[0]
        setEffectValue(effect)
    }

    const handleClose = () => {
        setCondition("")
        setEffectValue({
            id: "",
            text: ["", "", "", "", ""],
            comment: "",
            shouldEndSession: false,
        })
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Übergang</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Von {currentTransition?.source} (
                            {currentTransition?.sourceHandle}) nach{" "}
                            {currentTransition?.target} (
                            {currentTransition?.targetHandle})
                        </Typography>
                    </Grid>
                    {currentTransition?.sourceHandle === "effectNavigation" && (
                        <Grid item xs={12}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>
                                    Welche Bedingung muss erfüllt sein, um in den
                                    nächsten Zustand zu gehen?
                                </InputLabel>
                                <Select
                                    id="userInput"
                                    onChange={handleConditionChange}
                                    fullWidth
                                    value={condition}
                                >
                                    <MenuItem key="nothing" value="nothing">
                                        Keine (Gehe immer in den nächsten Zustand)
                                    </MenuItem>
                                    <MenuItem key="pausedMedia" value="pausedMedia">
                                        Es wurde in der letzten Session ein Audio
                                        pausiert
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Alexa Ausgabe</InputLabel>
                            <Select
                                id="userInput"
                                onChange={handleSelectChange}
                                fullWidth
                                value={effectValue.id}
                            >
                                {effects.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.id}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Chat
                            messages={[
                                {
                                    type: 0,
                                    text: getRandomAlphabetText(
                                        currentTransition.sourceHandle || "",
                                        alphabet
                                    ),
                                },
                                { type: 1, text: effectValue.text[0] },
                            ]}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button onClick={handleSubmit}>Speichern</Button>
            </DialogActions>
        </Dialog>
    )
}
