import React, { Component } from "react"
import { render } from "react-dom"
import theme from "./config/theme"
import { Header } from "./components/Header/Header"
import { DashboardContainer } from "./pages/Dashboard/DashboardContainer"
import { ThemeProvider } from "@mui/material/styles"
import { StatemachineProvider } from "./context/statemachine.context"
import { ReactFlowProvider } from "react-flow-renderer"
import { MessageProvider } from "./context/MessageContext"
import Joyride from "react-joyride"
import { Button, Typography } from "@mui/material"
import StatesTour from "./pages/Dashboard/components/tour/StatesTour"
import WorkingTour from "./pages/Dashboard/components/tour/WorkingTour"

export default class App extends Component {
    state: any = {
        run: false,
        steps: [
            {
                content: (
                    <Typography variant="h3">
                        Starten wir mit einem kurzen Überblick!
                    </Typography>
                ),
                placement: "center",
                target: "body",
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#effect-container",
                content: (
                    <Typography>
                        Hier werden die Ausgaben von Alexa definiert. Also alles was
                        Alexa dem Nutzer 'sagen' kann.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#alphabet-container",
                content: (
                    <Typography>
                        Hier werden die Eingaben vom Nutzer definiert. Also alles was
                        der Nutzer zu Alexa sagen kann.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#states-container",
                content: (
                    <Typography>
                        Hier wird der gesamte Ablauf des Skills definiert, die Alexa
                        Eingaben und Ausgaben werden verwendet und neue Zustände
                        werden definiert.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#save-buttons",
                content: (
                    <Typography>
                        Änderungen können jederzeit über EXPORT gespeichert werden.
                        Die gespeicherte Datei kann über IMPORT importiert werden und
                        es kann am bestehenden Projekt weiter gearbeitet werden.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
        ],
    }

    render() {
        const { steps, run } = this.state

        return (
            <div>
                <StatemachineProvider>
                    <ThemeProvider theme={theme}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                backgroundColor: "lightblue",
                                paddingLeft: 16,
                            }}
                        >
                            <Button
                                onClick={() =>
                                    this.setState({ ...this.state, run: true })
                                }
                            >
                                1. Einführung
                            </Button>
                            <StatesTour />
                            <WorkingTour />
                        </div>
                        <Joyride
                            steps={steps}
                            run={run}
                            showProgress
                            continuous
                            showSkipButton
                            scrollToFirstStep
                        />
                        <MessageProvider>
                            <ReactFlowProvider>
                                <Header />
                                <DashboardContainer />
                            </ReactFlowProvider>
                        </MessageProvider>
                    </ThemeProvider>
                </StatemachineProvider>
            </div>
        )
    }
}

render(<App />, document.getElementById("root"))
