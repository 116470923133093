import {
    Typography,
    Grid,
    Theme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { EffectItem } from "../../../../types"
import { makeStyles } from "@mui/styles"
import { Delete, ExpandMore, Edit } from "@mui/icons-material"
import StatemachineContext from "../../../../context/statemachine.context"
import { AddEffect } from "./AddEffect"
import { EditEffect } from "./EditEffect"
import { isArray } from "util"

const useStyles = makeStyles(
    (theme: Theme) => ({
        flex: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(0.5),
            },
        },
        red: {
            color: "red",
        },
    }),
    { name: "EffectsContainer" }
)

export const EffectsContainer = () => {
    const classes = useStyles()
    const { effects, setEffects, updateEffect } = useContext(StatemachineContext)

    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [currentIdToDelete, setCurrentIdToDelete] = useState<string>("")

    const handleAddEffectItem = (item: EffectItem) => {
        setEffects([...effects, item])
    }

    const handleDeleteAlphabetItemClick = (id: string) => {
        setCurrentIdToDelete(id)
        setOpenDelete(!openDelete)
    }

    const handleEditAlphabetItem = (item: EffectItem) => {
        updateEffect(item)
    }

    const handleDeleteDialogClose = () => {
        setCurrentIdToDelete("")
        setOpenDelete(!openDelete)
    }

    const handleDeleteAlphabetItem = () => {
        const index = effects.findIndex((item) => item.id === currentIdToDelete)
        effects.splice(index, 1)
        setEffects([...effects])
        handleDeleteDialogClose()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h5">Alexa Ausgabe</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="end"></Grid>
                </Grid>
                <Typography variant="body2" marginBottom={1}>
                    Ausgabe von Alexa: <i>"Herzlich Willkommen im Skill!"</i>
                </Typography>
                <AddEffect onAdd={handleAddEffectItem} />
            </Grid>
            <Grid item xs={12}>
                {effects.map((item) => (
                    <Accordion key={item.id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>{item.id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.flex}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            Alexa Ausgabe:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {Array.isArray(item?.text) &&
                                            item.text.length > 0 &&
                                            item?.text?.map((t) => (
                                                <Typography>
                                                    {t.startsWith("http") ? (
                                                        <audio controls>
                                                            <source
                                                                src={t}
                                                                type="audio/mpeg"
                                                            />
                                                        </audio>
                                                    ) : (
                                                        t
                                                    )}
                                                </Typography>
                                            ))}
                                    </Grid>
                                </Grid>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button
                                className={classes.red}
                                startIcon={<Delete />}
                                onClick={() =>
                                    handleDeleteAlphabetItemClick(item.id)
                                }
                            >
                                Löschen
                            </Button>
                            <EditEffect
                                effect={item}
                                onEdit={handleEditAlphabetItem}
                            />
                        </AccordionActions>
                    </Accordion>
                ))}
            </Grid>
            <Dialog open={openDelete} onClose={handleDeleteDialogClose}>
                <DialogTitle>Alexa Ausgabe löschen</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du die Alexa Ausgabe "<b>{currentIdToDelete}</b>"{" "}
                        wirklich löschen?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Abbrechen</Button>
                    <Button
                        className={classes.red}
                        onClick={handleDeleteAlphabetItem}
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
