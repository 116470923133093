import React from "react"
import { getEdgeCenter, getMarkerEnd, Position } from "react-flow-renderer"
import { Button } from "@mui/material"

// These are some helper methods for drawing the round corners
// The name indicates the direction of the path. "bottomLeftCorner" goes
// from bottom to the left and "leftBottomCorner" goes from left to the bottom.
// We have to consider the direction of the paths because of the animated lines.
const bottomLeftCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`
const leftBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`
const bottomRightCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`
const rightBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`
const leftTopCorner = (x: number, y: number, size: number): string =>
    `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`
const topLeftCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`
const topRightCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`
const rightTopCorner = (x: number, y: number, size: number): string =>
    `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`

interface GetSmoothStepPathParams {
    sourceX: number
    sourceY: number
    sourcePosition?: Position
    targetX: number
    targetY: number
    targetPosition?: Position
    borderRadius?: number
    centerX?: number
    centerY?: number
}

const padding = 10
const halfPadding = padding / 2

export function getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition = Position.Bottom,
    targetX,
    targetY,
    targetPosition = Position.Top,
    borderRadius = 5,
    centerX,
    centerY,
}: GetSmoothStepPathParams): string {
    const [_centerX, _centerY, offsetX, offsetY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })
    const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX))
    const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY))
    const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY)
    const leftAndRight = [Position.Left, Position.Right]
    const cX = typeof centerX !== "undefined" ? centerX : _centerX
    const cY = typeof centerY !== "undefined" ? centerY : _centerY

    let firstCornerPath = null
    let secondCornerPath = null

    let sourceHPadding = ""
    let targetHPadding = ""

    console.log("sourceX", sourceX + padding, "targetX", targetX)
    if (sourceX + padding <= targetX) {
        console.log("first if", "sourceY", sourceY, "targetY", targetY)
        firstCornerPath =
            sourceY <= targetY
                ? bottomRightCorner(sourceX, cY, cornerSize)
                : topLeftCorner(sourceX, cY, cornerSize)
        secondCornerPath =
            sourceY <= targetY
                ? leftTopCorner(targetX, cY, cornerSize)
                : rightBottomCorner(targetX, cY, cornerSize)
    } else {
        console.log("first else", "sourceY", sourceY, "targetY", targetY)
        // here is the case specifically where new corners need introduced
        firstCornerPath =
            sourceY < targetY
                ? leftBottomCorner(sourceX + halfPadding, cY, cornerSize)
                : topRightCorner(sourceX + halfPadding, cY, cornerSize)
        sourceHPadding = `${
            sourceY <= targetY
                ? rightTopCorner(sourceX + halfPadding, sourceY, cornerSize)
                : rightBottomCorner(sourceX + halfPadding, sourceY, cornerSize)
        }`
        secondCornerPath =
            sourceY < targetY
                ? leftBottomCorner(targetX - halfPadding, cY, cornerSize)
                : leftBottomCorner(targetX - halfPadding, cY, cornerSize)
        targetHPadding = `${
            sourceY < targetY
                ? leftBottomCorner(targetX - halfPadding, targetY, cornerSize)
                : topLeftCorner(targetX - halfPadding, targetY, cornerSize)
        }`
    }

    if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
    ) {
        console.log("in second if")
        if (sourceX + padding <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(cX, sourceY, cornerSize)
                    : rightBottomCorner(cX, sourceY, cornerSize)
            secondCornerPath =
                sourceY <= targetY
                    ? bottomLeftCorner(cX, targetY, cornerSize)
                    : topLeftCorner(cX, targetY, cornerSize)
        }
    } else if (
        leftAndRight.includes(sourcePosition) &&
        !leftAndRight.includes(targetPosition)
    ) {
        console.log("in third if")

        if (sourceX + padding <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(targetX, sourceY, cornerSize)
                    : rightBottomCorner(targetX, sourceY, cornerSize)
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(sourceX, targetY, cornerSize)
                    : topRightCorner(sourceX, targetY, cornerSize)
        }
        secondCornerPath = ""
    } else if (
        !leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
    ) {
        console.log("in 4 if")

        if (sourceX + padding <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomLeftCorner(sourceX, targetY, cornerSize)
                    : topLeftCorner(sourceX, targetY, cornerSize)
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(sourceX, targetY, cornerSize)
                    : topRightCorner(sourceX, targetY, cornerSize)
        }
        secondCornerPath = ""
    }

    return `M ${sourceX},${sourceY}${sourceHPadding}${firstCornerPath}${secondCornerPath}${targetHPadding}L ${targetX},${targetY}`
}

const foreignObjectSize = 120

const onEdgeClick = (evt: any, id: string) => {
    evt.stopPropagation()
    alert(`remove ${id}`)
}

interface CustomEdgeProps {
    id: string
    sourceX: number
    sourceY: number
    targetX: number
    targetY: number
    sourcePosition: Position | undefined
    targetPosition: Position | undefined
    style: Object
    data: any
    arrowHeadType: any
    markerEndId: any
    centerX: any
    centerY: any
}

export const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
    centerX,
    centerY,
}: CustomEdgeProps) => {
    const edgePath = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: 5,
        centerX,
        centerY,
    })
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 4}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <body>
                    <Button onClick={(event) => onEdgeClick(event, id)}>
                        {data?.label}
                    </Button>
                </body>
            </foreignObject>
        </>
    )
}
