import { AlphabetItem } from "../types"

function getRandomAlphabetText(id: string, alphabet: AlphabetItem[]): string {
    if (!id || !alphabet || alphabet.length < 1) {
        return ""
    }

    const alph = alphabet.filter((al) => al.id === id)?.[0]

    if (!alph) {
        return ""
    }

    return getRandomItem(alph.synonyms)
}

function getRandomItem(array: string[]) {
    return array[Math.floor(Math.random() * array.length)]
}

export { getRandomAlphabetText }
