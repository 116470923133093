import React, { createContext, useState } from "react"
import { AlphabetItem, EffectItem, StateItem, Transition } from "../types"

type StatemachineContextParams = {
    alphabet: AlphabetItem[]
    effects: EffectItem[]
    states: StateItem[]
    globalTransitions: Transition[]
    setAlphabet: (alphabet: AlphabetItem[]) => void
    updateAlphabet: (alphabetItem: AlphabetItem) => void
    setEffects: (effects: EffectItem[]) => void
    updateEffect: (effect: EffectItem) => void
    setStates: (states: StateItem[]) => void
    setGlobalTransitions: (transitions: Transition[]) => void
}

const StatemachineContext = createContext<StatemachineContextParams>({
    alphabet: [],
    effects: [],
    states: [],
    globalTransitions: [],
    setAlphabet: () => null,
    updateAlphabet: () => null,
    setEffects: () => null,
    updateEffect: () => null,
    setStates: () => null,
    setGlobalTransitions: () => null,
})

export const StatemachineProvider: React.FunctionComponent = ({ children }) => {
    const [alphabet, setAlphabetData] = useState<AlphabetItem[]>([])
    const [states, setStates] = useState<StateItem[]>([
        {
            id: "START",
            transitions: [
                {
                    effect: "firstVisit",
                    target: "FIRST_VISIT",
                    targetHandle: "input",
                    source: "START",
                    sourceHandle: "firstVisit",
                },
                {
                    effect: "nextVisit",
                    target: "NEXT_VISIT",
                    targetHandle: "input",
                    source: "START",
                    sourceHandle: "nextVisit",
                },
            ],
            type: "start",
            allowedAlphabet: [],
            position: { x: 350, y: 15 },
        },
        {
            id: "FIRST_VISIT",
            transitions: [],
            allowedAlphabet: [],
            position: { x: 50, y: 100 },
        },
        {
            id: "NEXT_VISIT",
            transitions: [],
            allowedAlphabet: [],
            position: { x: 600, y: 100 },
        },
        {
            id: "PLAY_AUDIO",
            transitions: [],
            type: "audioPlayer",
            allowedAlphabet: [],
            position: { x: 800, y: 300 },
        },
        {
            id: "END",
            transitions: [],
            allowedAlphabet: [],
            type: "end",
            position: { x: 350, y: 700 },
        },
    ])
    const [effects, setEffects] = useState<EffectItem[]>([
        {
            id: "firstVisit",
            text: [
                "Herzlich Willkommen bei deinem ersten Besuch im Skill! Möchtest du in das Menü?",
                "",
                "",
                "",
                "",
            ],
            comment: "",
            shouldEndSession: false,
        },
        {
            id: "nextVisit",
            text: ["Willkommen zurück! Möchtest du in das Menü?", "", "", "", ""],
            comment: "",
            shouldEndSession: false,
        },
    ])
    const [globalTransitions, setGlobalTransitions] = useState<Transition[]>([])

    const setAlphabet = (alphabet: AlphabetItem[]) => {
        const newAlphabet: AlphabetItem[] = []

        alphabet.map((a) => {
            const synonyms = a.synonyms.reduce((acc: string[], curr: string) => {
                if (!acc.includes(curr)) {
                    acc.push(curr)
                }

                return acc
            }, [])

            newAlphabet.push({ ...a, synonyms })
            setAlphabetData(newAlphabet)
        })
    }

    const updateEffect = (effect: EffectItem) => {
        const updateEffects = [...effects]

        if (effect?.id) {
            const index = updateEffects.findIndex((e) => e.id === effect?.id)

            if (index !== -1) {
                updateEffects[index] = effect
                setEffects([...updateEffects])
            }
        }
    }

    const updateAlphabet = (alphabetItem: AlphabetItem) => {
        const updateAlphabet = [...alphabet]

        if (alphabetItem?.id) {
            const index = updateAlphabet.findIndex((e) => e.id === alphabetItem?.id)
            alphabetItem.synonyms = alphabetItem.synonyms.reduce(
                (acc: string[], curr: string) => {
                    if (!acc.includes(curr)) {
                        acc.push(curr)
                    }

                    return acc
                },
                []
            )

            if (index !== -1) {
                updateAlphabet[index] = alphabetItem
                setAlphabet([...updateAlphabet])
            }
        }
    }

    return (
        <StatemachineContext.Provider
            value={{
                alphabet,
                setAlphabet,
                updateAlphabet,
                effects,
                setEffects,
                updateEffect,
                states,
                setStates,
                globalTransitions,
                setGlobalTransitions,
            }}
        >
            {children}
        </StatemachineContext.Provider>
    )
}

export default StatemachineContext
