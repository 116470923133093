import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material"
import ReactFlow, {
    Background,
    Connection,
    Edge,
    FlowElement,
    Handle,
    MiniMap,
    Node,
    Position,
    ReactFlowProvider,
    SmoothStepEdge,
    useUpdateNodeInternals,
} from "react-flow-renderer"
import React, { FormEvent, useContext, useState } from "react"
import { AddState } from "./states/AddState"
import { transformStateToDiagram } from "../../../utils/transformer"
import StatemachineContext from "../../../context/statemachine.context"
import { StateItem, Transition } from "../../../types"
import { EditState } from "./states/EditState"
import { CustomNode } from "./states/CustomNode"
import { NewTransition } from "./states/NewTransition"
import { StartNode } from "./states/StartNode"
import { EndNode } from "./states/EndNode"
import { CustomEdge } from "./states/ButtonEdge"
import { AudioPlayerNode } from "./states/AudioPlayerNode"

export const StatesContainer = () => {
    const { states, setStates, alphabet } = useContext(StatemachineContext)
    const [open, setOpen] = useState(false)
    const [selectedState, setSelectedState] = useState<StateItem>()
    const [currentTransition, setCurrentTransition] = useState<Transition>({
        alphabet: "",
        effect: "",
        source: "",
        sourceHandle: "",
        target: "",
        targetHandle: "",
    })

    const [openTransition, setOpenTransition] = useState<boolean>(false)
    const updateNodeInternals = useUpdateNodeInternals()

    const handleTransitionDialogClose = () => {
        setCurrentTransition({
            alphabet: "",
            effect: "",
            source: "",
            sourceHandle: "",
            target: "",
            targetHandle: "",
        })
        setOpenTransition(false)
    }

    const handleTransitionDialogOpen = () => {
        setOpenTransition(true)
    }

    const handleConnect = (params: Connection | Edge<any>) => {
        handleTransitionDialogOpen()
        // @ts-ignore
        setCurrentTransition(params)
    }

    const handleSubmit = (transition: Transition) => {
        if (transition) {
            updateNodeInternals(transition.sourceHandle || "")
            updateNodeInternals(transition.targetHandle || "")
            updateNodeInternals(transition.source || "")
            updateNodeInternals(transition.target || "")
            updateNodeInternals(`${transition.source}-${transition.target}`)
            const index = states.findIndex((e) => e.id === transition?.source)
            states[index].transitions.push(transition)
            setStates([...states])
            handleTransitionDialogClose()
        }
    }

    const handleAddState = (item: StateItem) => {
        setStates([...states, item])
    }

    const handleDialog = () => {
        setOpen(!open)
    }

    const onElementClick = (
        event: React.MouseEvent<Element, MouseEvent>,
        element: Node | Edge
    ) => {
        if (element.type !== "default") {
            return
        }

        const index = states.findIndex((item) => item.id === element.id)

        if (index > -1) {
            setSelectedState(states[index])
            handleDialog()
        }
    }

    const handleStateUpdate = (state: StateItem) => {
        const index: number = states.findIndex((e) => e.id === state.id)

        if (index > -1) {
            states[index] = { ...states[index], ...state }
            setStates([...states])
            setSelectedState(undefined)
            handleDialog()
        }
    }

    const handleDeleteState = (state: StateItem) => {
        setStates([...states.filter((e) => e.id !== state.id)])
        setSelectedState(undefined)
        handleDialog()
    }

    const handleDragStop = (e: any, node: Node) => {
        const index: number = states.findIndex((e) => e.id === node.id)

        if (index > -1) {
            states[index] = {
                ...states[index],
                id: node.id,
                position: node.position,
            }
            setStates([...states])
            setSelectedState(undefined)
        }
    }

    return (
        <Card id="states-container">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ margin: 16 }}>
                    <Typography variant="h5">States</Typography>
                    <Typography variant="body2" marginBottom={1}>
                        Zustände in denen sich der Nutzer befinden kann. Beispiel
                        "START" (Öffnen des Skills)
                    </Typography>
                    <AddState onAdd={handleAddState} />
                </Grid>
                <Grid item xs={12} style={{ height: "70vh" }}>
                    <ReactFlow
                        onElementClick={onElementClick}
                        edgeTypes={{ custom: CustomEdge }}
                        onConnect={handleConnect}
                        onlyRenderVisibleElements={false}
                        onNodeDragStop={handleDragStop}
                        elements={[...transformStateToDiagram(states, alphabet)]}
                        style={{
                            backgroundColor: "#00345e",
                        }}
                        nodeTypes={{
                            default: CustomNode,
                            start: StartNode,
                            end: EndNode,
                            audioPlayer: AudioPlayerNode,
                        }}
                    >
                        <Background />
                    </ReactFlow>
                </Grid>
                <EditState
                    open={open}
                    onUpdate={handleStateUpdate}
                    onClose={handleDialog}
                    onDelete={handleDeleteState}
                    selectedState={selectedState}
                />
                <NewTransition
                    open={openTransition}
                    onSubmit={handleSubmit}
                    onClose={handleTransitionDialogClose}
                    currentTransition={currentTransition}
                />
            </Grid>
        </Card>
    )
}
