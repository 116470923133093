import { AppBar, Button, Grid, Toolbar, Typography } from "@mui/material"
import { makeStyles, styled } from "@mui/styles"
import { CloudUpload, Save } from "@mui/icons-material"
import React, { ChangeEvent, useContext } from "react"
import StatemachineContext from "../../context/statemachine.context"
import { useUpdateNodeInternals } from "react-flow-renderer"
import {
    createStatemachineConfigFile,
    createEffectFiles,
    transformSpeakOutput,
    transformToStateMachineOutput,
    createReadmeFile,
    createInteractionModel,
} from "../../utils/transformer"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const Input = styled("input")({
    display: "none",
})

const useStyles = makeStyles(() => ({
    button: {
        color: "white !important",
    },
    right: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
}))

export const Header = () => {
    const classes = useStyles()
    const updateNodeInternals = useUpdateNodeInternals()
    const {
        states,
        setStates,
        alphabet,
        setAlphabet,
        effects,
        setEffects,
        globalTransitions,
    } = useContext(StatemachineContext)

    const handleSave = () => {
        const fileData = JSON.stringify({ states, alphabet, effects })
        const blob = new Blob([fileData], { type: "text/plain" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.download = "statemachine.json"
        link.href = url
        link.click()
    }

    const handleSaveStatemachine = () => {
        let zip = new JSZip()

        const options = transformToStateMachineOutput(
            states,
            alphabet,
            globalTransitions
        )

        createEffectFiles(effects, states, zip)
        createStatemachineConfigFile(options, effects, states, zip)
        createReadmeFile(zip)
        createInteractionModel(alphabet, zip)

        const speakFileData = transformSpeakOutput(effects)
        const blobSpeak = new Blob([speakFileData], { type: "text/plain" })
        zip.file("config/speak.ts", blobSpeak)

        zip.generateAsync({
            type: "blob",
        }).then(function (content) {
            saveAs(content, "hilbert.zip")
        })
    }

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]

        if (!file) {
            return
        }

        const fileReader = new FileReader()
        fileReader.readAsText(file, "UTF-8")
        fileReader.onload = (fileEvent) => {
            try {
                const data = JSON.parse(fileEvent?.target?.result as string)
                setStates(data.states)
                setAlphabet(data.alphabet)
                setEffects(data.effects)

                updateNodeInternals("FIRST_VISIT")
                updateNodeInternals("NEXT_VISIT")
            } catch (e) {
                return null
            }
        }
    }

    return (
        <AppBar position="static">
            <Toolbar>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                            Der junge Hilbert{" "}
                            <i style={{ color: "orange", fontSize: 14 }}>
                                &nbsp;&nbsp;( Alpha-version)
                            </i>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.center} id="save-buttons">
                        <label htmlFor="upload">
                            <Input
                                accept="application/json"
                                id="upload"
                                type="file"
                                onChange={handleUpload}
                            />
                            <Button
                                className={classes.button}
                                component="span"
                                startIcon={
                                    <CloudUpload className={classes.button} />
                                }
                            >
                                Import
                            </Button>
                            <Button
                                className={classes.button}
                                startIcon={<Save className={classes.button} />}
                                onClick={handleSave}
                            >
                                Export
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs={4} className={classes.right}>
                        <Button
                            className={classes.button}
                            startIcon={<Save className={classes.button} />}
                            onClick={handleSaveStatemachine}
                        >
                            Entwickler Export
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}
