import { Button, Typography } from "@mui/material"
import React, { Component } from "react"
import Joyride from "react-joyride"

export default class WorkingTour extends Component {
    state: any = {
        run: false,
        steps: [
            {
                content: (
                    <Typography variant="h3">
                        Starten wir mit einem Beispiel!
                    </Typography>
                ),
                placement: "center",
                target: "body",
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                content: (
                    <Typography>
                        Lege zwei Alexa Eingaben an "Ja" und "Nein". Du kannst auch
                        die Templates verwenden.
                    </Typography>
                ),
                target: "#add-alphabet-button",
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#add-effect-button",
                content: (
                    <Typography>
                        Lege nun eine Alexa Ausgabe mit einem Text zur Verabschiedung
                        an, eine Ausgabe, die einen Text ausgibt, wenn der Nutzer in
                        das Menü geht und eine Ausgabe, wenn ein Fehler auftritt.
                        (Z.B. "Das habe ich nicht verstanden, kannst du das bitte
                        noch einmal sagen?")
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: ".CustomNode-root-16",
                content: (
                    <Typography>
                        Klicke nun auf den State und wähle als Nutzereingabe deine
                        beiden angelegten Alexa Eingaben <b>Ja</b> und <b>Nein</b>{" "}
                        aus.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#add-state-button",
                content: (
                    <Typography>
                        Lege einen neuen State mit der ID: MENU an.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: ".CustomNode-root-16",
                content: (
                    <Typography>
                        Lege nun einen neuen Übergang an, indem du von dem grünen
                        Kreis <b>No</b> eine Linie zum <b>End</b> State ziehst. Bei
                        erfolgreicher Verbindung erscheint ein neues Fenster, hier
                        wählst du als Alexa Ausgabe, deine angelegte Ausgabe zum
                        Verabschieden aus.
                        <br />
                        <br />
                        Danach ziehst du eine Linie vom grünen <b>Yes</b> Punkt zum
                        schwarzen Eingangspunkt vom <b>MENU</b> State und wählst
                        deine Alexa Ausgabe für das Menü aus.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: ".CustomNode-root-16",
                content: (
                    <Typography>
                        Zum Schluss ziehst du von dem roten Punkt vom{" "}
                        <b>FIRST_VISIT</b> State eine Linie zum Eingangspunkt vom
                        gleichen State. Das bedeutet bei einem Fehlerfall bleibst du
                        weiterhin in diesem State und fragst den Nutzer noch einmal
                        nach einer Eingabe.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
        ],
    }

    render() {
        const { steps, run } = this.state

        return (
            <>
                <Button onClick={() => this.setState({ ...this.state, run: true })}>
                    3. Starten
                </Button>
                <Joyride
                    steps={steps}
                    run={run}
                    showProgress
                    continuous
                    showSkipButton
                    scrollToFirstStep
                />
            </>
        )
    }
}
