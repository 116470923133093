import { Typography } from "@mui/material"
import { Handle, Position } from "react-flow-renderer"

export const StartNode = ({ data }: { data: any }) => {
    return (
        <div id="start-node">
            <div>
                <Typography>{data.label}</Typography>
            </div>
            <Handle type="source" id="start" position={Position.Bottom} />
        </div>
    )
}
