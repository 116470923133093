import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Theme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Add, Edit } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { TEMPLATES } from "../../../../config/alphabetTemplates"
import { AlphabetItem, AlphabetTemplateOption } from "../../../../types"

const useStyles = makeStyles(
    (theme: Theme) => ({
        flex: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(0.5),
            },
        },
        submit: {
            position: "absolute",
            bottom: theme.spacing(2),
        },
    }),
    { name: "AddAlphabet" }
)

interface EditAlphabetProps {
    onEdit: (item: AlphabetItem) => void
    alphabet: AlphabetItem
}

export const EditAlphabet = ({ onEdit, alphabet }: EditAlphabetProps) => {
    const classes = useStyles()

    const [synonyms, setSynonyms] = useState<string[]>([])
    const [currentSynonym, setCurrentSynonym] = useState("")
    const [id, setId] = useState("")
    const [currentSynonymError, setCurrentSynonymError] = useState("")
    const [template, setTemplate] = useState("")
    const [open, setOpen] = useState<boolean>(false)
    const [isGlobal, setIsGlobal] = useState(false)

    useEffect(() => {
        setSynonyms(alphabet.synonyms)
        setId(alphabet.id)
        setIsGlobal(!!alphabet.isGlobal)
    }, [alphabet])

    const handleDialog = () => {
        setOpen(!open)
    }

    const handleDeleteSynonym = (value: string) => {
        const index = synonyms.indexOf(value)
        synonyms.splice(index, 1)
        setSynonyms([...synonyms])
    }

    const handleAddSynonym = () => {
        if (!currentSynonym || !currentSynonym.trim()) {
            setCurrentSynonymError(
                "Bitte trage mindestens ein Synonym ein. (Was könnte der Nutzer sagen?)"
            )
            return
        }

        const synonymLowerCase: string = currentSynonym.trim().toLowerCase()

        setCurrentSynonymError("")
        setCurrentSynonym("")

        if (!synonyms.includes(synonymLowerCase)) {
            setSynonyms([...synonyms, synonymLowerCase])
        }
    }

    const handleCurrentSynonymChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentSynonym(e.target.value)
    }

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value)
    }

    const handleTemplateSelect = (e: SelectChangeEvent) => {
        setTemplate(e.target.value)
        setId(e.target.value)
        setSynonyms(TEMPLATES[e.target.value as AlphabetTemplateOption] || [])
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        handleDialog()
        onEdit({ id, synonyms, isGlobal })

        setSynonyms([])
        setCurrentSynonymError("")
        setId("")
        setTemplate("")
    }

    return (
        <>
            <Button
                type="submit"
                startIcon={<Edit />}
                onClick={handleDialog}
                id="add-alphabet-button"
            >
                Bearbeiten
            </Button>
            <Dialog open={open} onClose={handleDialog}>
                <DialogTitle>Nutzereingabe bearbeiten</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="ID"
                                    placeholder='Bsp.: "yes" oder "help"'
                                    variant="standard"
                                    fullWidth
                                    required
                                    disabled
                                    value={id}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Synonym"
                                    placeholder='Bsp.: "Öffne die Hilfe"'
                                    variant="standard"
                                    fullWidth
                                    value={currentSynonym}
                                    error={!!currentSynonymError}
                                    helperText={
                                        currentSynonymError ||
                                        "Füge einzelne Synonyme mit Klick auf das Plus hinzu. Mehrere Synonyme möglich."
                                    }
                                    onChange={handleCurrentSynonymChange}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                            handleAddSynonym()
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleAddSynonym}
                                                >
                                                    <Add />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.flex}>
                                {synonyms.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={() => handleDeleteSynonym(value)}
                                    />
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="global"
                                    onChange={() => setIsGlobal(!isGlobal)}
                                    value={isGlobal}
                                    control={<Checkbox defaultChecked={isGlobal} />}
                                    label="Kann diese Eingabe an jeder Stelle im Skill gemacht werden? (Ein gutes Beispiel ist die 'Hilfe')"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" disabled={synonyms.length < 1}>
                            Speichern
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
