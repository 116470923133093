import { Button, Typography } from "@mui/material"
import React, { Component } from "react"
import Joyride from "react-joyride"

export default class StatesTour extends Component {
    state: any = {
        run: false,
        steps: [
            {
                content: (
                    <Typography variant="h3">
                        Weiter geht's mit den States!
                    </Typography>
                ),
                placement: "center",
                target: "body",
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                content: (
                    <Typography>
                        Das ist der Einstiegspunkt vom Skill. Von hier gehen zwei
                        Linien weg Richtung "FIRST_VISIT" und "NEXT_VISIT". Beim
                        ersten Besuch des Skills folgt man der linken Linie, bei
                        allen weiteren Besuchen der Rechten.
                    </Typography>
                ),
                target: "#start-node",
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: ".react-flow__edge-textbg",
                content: (
                    <Typography>
                        Das ist eine Alexa Ausgabe, die auf der rechten Seite
                        definiert wurde. Das heißt bei dem Übergang von "START" zu
                        dem State "FIRST_VISIT" wird die Alexa Ausgabe "firstVisit"
                        ausgegeben.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: ".CustomNode-root-16",
                content: (
                    <Typography>
                        Hier ist man im State <b>FIRST_VISIT</b> angekommen. An jedem
                        State befindet sich ein <b>schwarzer Punkt</b> (Die Einstieg
                        zum State), ein{" "}
                        <span style={{ color: "red" }}>roter Punkt</span> (Was soll
                        im Fehlerfalle geschehen) und ein{" "}
                        <span style={{ color: "orange" }}>orangener Punkt</span>{" "}
                        (Übergang in einen anderen State, wenn eine bestimmte
                        Bedingung erfüllt ist)
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
            {
                target: "#end-node",
                content: (
                    <Typography>
                        Das ist das Ende vom Skill, hier müssen alle Verbindungen zum
                        Schluss zusammen laufen.
                    </Typography>
                ),
                locale: {
                    skip: "Schließen",
                    back: "Zurück",
                    next: "Weiter",
                    last: "Fertig",
                },
                styles: {
                    tooltipContainer: {
                        textAlign: "left",
                    },
                },
            },
        ],
    }

    render() {
        const { steps, run } = this.state

        return (
            <>
                <Button onClick={() => this.setState({ ...this.state, run: true })}>
                    2. States
                </Button>
                <Joyride
                    steps={steps}
                    run={run}
                    showProgress
                    continuous
                    showSkipButton
                    scrollToFirstStep
                />
            </>
        )
    }
}
