import { Typography } from "@mui/material"
import { Handle, Position } from "react-flow-renderer"
import React from "react"

export const AudioPlayerNode = ({ data }: { data: any }) => {
    return (
        <div id="audio-player-node">
            <div>
                <Typography>{data.label}</Typography>
            </div>
            <Handle
                type="target"
                id="input"
                position={Position.Top}
                style={{ width: 10, height: 10, top: -20 }}
            />
        </div>
    )
}
