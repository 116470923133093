import { Button, Theme } from "@mui/material"
import { Download, Upload } from "@mui/icons-material"
import React, { useContext, useRef } from "react"
import { CSVDownloader, CSVReader } from "react-papaparse"
import { makeStyles } from "@mui/styles"
import StatemachineContext from "../../../../context/statemachine.context"
import {
    transformAlphabetToCSVData,
    transformCsvDataToAlphabet,
} from "../../../../utils/transformer"

const useStyles = makeStyles(
    (theme: Theme) => ({
        flex: {
            display: "flex",
            height: 35,
            justifyContent: "flex-end",
        },
    }),
    { name: "ImportExportAlphabet" }
)

export const ImportExportAlphabet = () => {
    const classes = useStyles()
    const buttonRef = useRef()

    const { alphabet, setAlphabet } = useContext(StatemachineContext)

    // @ts-ignore
    const handleOnFileLoad = (data, fileInfo) => {
        if (fileInfo?.type !== "text/csv") {
            return
        }

        setAlphabet(transformCsvDataToAlphabet(data))
    }

    // @ts-ignore
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    // @ts-ignore
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            // @ts-ignore
            buttonRef.current.open(e)
        }
    }

    return (
        <div className={classes.flex}>
            <CSVReader
                // @ts-ignore
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                progressBarColor="#ff8800"
            >
                {({ file }: { file: File }) => (
                    <aside>
                        <Button onClick={handleOpenDialog} startIcon={<Upload />}>
                            Import
                        </Button>
                    </aside>
                )}
            </CSVReader>

            {alphabet && alphabet.length > 0 ? (
                <CSVDownloader
                    data={transformAlphabetToCSVData(alphabet)}
                    filename="alphabet"
                    bom={true}
                >
                    <Button startIcon={<Download />}>Export</Button>
                </CSVDownloader>
            ) : (
                <Button disabled startIcon={<Download />}>
                    Export
                </Button>
            )}
        </div>
    )
}
