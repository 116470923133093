import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Add, Edit } from "@mui/icons-material"
import { EffectItem } from "../../../../types"

interface EditEffectProps {
    onEdit: (item: EffectItem) => void
    effect: EffectItem
}

export const EditEffect = ({ onEdit, effect }: EditEffectProps) => {
    const [id, setId] = useState(effect.id)
    const [text1, setText1] = useState(effect.text[0])
    const [text2, setText2] = useState(effect.text[1])
    const [text3, setText3] = useState(effect.text[2])
    const [text4, setText4] = useState(effect.text[3])
    const [text5, setText5] = useState(effect.text[4])
    const [reprompt, setReprompt] = useState(effect.reprompt)
    const [comment, setComment] = useState(effect.comment)
    const [shouldEndSession, setShouldEndSession] = useState(false)
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        setId(effect.id)
        setText1(effect.text[0])
        setText2(effect.text[1])
        setText3(effect.text[2])
        setText4(effect.text[3])
        setText5(effect.text[4])
        setComment(effect.comment)
        setShouldEndSession(effect.shouldEndSession)
    }, [effect])

    const handleDialog = () => {
        setOpen(!open)
    }

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value)
    }

    const handleTextChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText1(e.target.value)
    }

    const handleTextChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText2(e.target.value)
    }

    const handleTextChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText3(e.target.value)
    }

    const handleTextChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText4(e.target.value)
    }

    const handleTextChange5 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText5(e.target.value)
    }

    const handleRepromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReprompt(e.target.value)
    }

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        handleDialog()

        onEdit({
            ...effect,
            id,
            text: [text1, text2, text3, text4, text5],
            reprompt,
            comment,
            shouldEndSession,
        })
        setId("")
        setText1("")
        setText2("")
        setText3("")
        setText4("")
        setText5("")
        setComment("")
        setShouldEndSession(false)
    }

    return (
        <>
            <Button
                type="submit"
                startIcon={<Edit />}
                onClick={handleDialog}
                id="edit-effect-button"
            >
                Bearbeiten
            </Button>
            <Dialog open={open} onClose={handleDialog}>
                <DialogTitle>Alexa Ausgabe bearbeiten</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    label="ID"
                                    placeholder='Bsp.: "openMenu" oder "openHelp"'
                                    variant="standard"
                                    fullWidth
                                    required
                                    value={id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="1. Speak (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Herzlich Willkommen im Skill!"'
                                    variant="standard"
                                    fullWidth
                                    required
                                    value={text1}
                                    onChange={handleTextChange1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="2. Speak (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Herzlich Willkommen im Skill!"'
                                    variant="standard"
                                    fullWidth
                                    value={text2}
                                    onChange={handleTextChange2}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="3. Speak (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Herzlich Willkommen im Skill!"'
                                    variant="standard"
                                    fullWidth
                                    value={text3}
                                    onChange={handleTextChange3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="4. Speak (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Herzlich Willkommen im Skill!"'
                                    variant="standard"
                                    fullWidth
                                    value={text4}
                                    onChange={handleTextChange4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="5. Speak (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Herzlich Willkommen im Skill!"'
                                    variant="standard"
                                    fullWidth
                                    value={text5}
                                    onChange={handleTextChange5}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Reprompt (Text oder Link zum Audio)"
                                    placeholder='Bsp.: "Was möchtest du jetzt machen?"'
                                    variant="standard"
                                    fullWidth
                                    value={reprompt}
                                    onChange={handleRepromptChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    name="withShouldEndSession"
                                    onChange={() =>
                                        setShouldEndSession(!shouldEndSession)
                                    }
                                    value={shouldEndSession}
                                    control={
                                        <Checkbox
                                            defaultChecked={shouldEndSession}
                                        />
                                    }
                                    label="Wird nach dem Text KEINE Antwort erwartet?"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Kommentar für Entwickler"
                                    placeholder='Was für eine Logik soll noch abgebildet werden? Z.B. "Bei jedem zweiten Mal einen anderen Text ausgeben.", oder "Ein zufälliges Audio auspielen."'
                                    multiline
                                    maxRows={5}
                                    minRows={5}
                                    variant="standard"
                                    fullWidth
                                    value={comment}
                                    onChange={handleCommentChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit">Speichern</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
