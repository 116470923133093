import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { EffectItem, StateItem } from "../../../../types"
import { Add, Delete } from "@mui/icons-material"
import StatemachineContext from "../../../../context/statemachine.context"
import { useUpdateNodeInternals } from "react-flow-renderer"

interface EditStateProps {
    open: boolean
    onClose: () => void
    onUpdate: (state: StateItem) => void
    selectedState: StateItem | undefined
    onDelete: (state: StateItem) => void
}

export const EditState = ({
    open,
    onClose,
    selectedState,
    onUpdate,
    onDelete,
}: EditStateProps) => {
    const { alphabet, effects } = useContext(StatemachineContext)
    const [selectedUserInput, setSelectedUserInput] = useState<string[]>([])
    const [isGlobal, setIsGlobal] = useState(false)
    const [id, setId] = useState(selectedState?.id || "")
    const updateNodeInternals = useUpdateNodeInternals()
    const [effectValue, setEffectValue] = useState<EffectItem>({
        id: "",
        text: ["", "", "", "", ""],
        comment: "",
        shouldEndSession: false,
    })

    useEffect(() => {
        setSelectedUserInput(selectedState?.allowedAlphabet || [])
    }, [selectedState?.transitions])

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value)
    }

    const handleChipChange = (e: SelectChangeEvent) => {
        // @ts-ignore
        setSelectedUserInput(e.target.value as string[])
    }

    const handleSubmit = (e: React.FormEvent) => {
        console.log(e)
        e.preventDefault()

        onUpdate({
            id: id || selectedState?.id || "",
            transitions: selectedState?.transitions || [],
            allowedAlphabet: selectedUserInput,
        })

        setId("")
        setSelectedUserInput([])

        updateNodeInternals("test")
        // somewhere in your app
        selectedUserInput.map((el) => updateNodeInternals(el))
    }

    const handleSelectChange = (e: SelectChangeEvent) => {
        const effect = effects.filter((eff) => eff.id === e.target.value)?.[0]
        setEffectValue(effect)
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>State bearbeiten</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="ID"
                                placeholder='Bsp.: "MENU" oder "HELP"'
                                variant="standard"
                                fullWidth
                                required
                                value={id || selectedState?.id}
                                onChange={handleIdChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>
                                        Was für Nutzereingaben sind in dem Zustand
                                        erlaubt?
                                    </InputLabel>
                                    <Select
                                        id="userInput"
                                        multiple
                                        onChange={handleChipChange}
                                        fullWidth
                                        // @ts-ignore
                                        value={selectedUserInput}
                                    >
                                        {alphabet.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.id}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                name="global"
                                onChange={() => setIsGlobal(!isGlobal)}
                                control={<Checkbox />}
                                label="Kann man in diesen State durch eine globale Alexa Eingabe gelangen? (Alexa Eingabe muss als global gekenzeichnet sein)"
                            />
                        </Grid>
                        {isGlobal && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel>
                                            Erreichbar durch globale Eingabe
                                        </InputLabel>
                                        <Select
                                            id="userInput"
                                            multiple
                                            onChange={handleChipChange}
                                            fullWidth
                                            // @ts-ignore
                                            value={selectedUserInput}
                                        >
                                            {alphabet
                                                .filter((i) => i.isGlobal)
                                                .map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.id}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel>Alexa Ausgabe</InputLabel>
                                        <Select
                                            id="userInput"
                                            onChange={handleSelectChange}
                                            fullWidth
                                            value={effectValue.id}
                                        >
                                            {effects.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.id}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                {selectedState && (
                    <DialogActions>
                        <Button
                            color="error"
                            onClick={() => onDelete(selectedState)}
                        >
                            Löschen
                        </Button>
                        <Button color="primary" type="submit">
                            Speichern
                        </Button>
                    </DialogActions>
                )}
            </form>
        </Dialog>
    )
}
