import {
    Typography,
    Grid,
    Theme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    AccordionActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material"
import { AddAlphabet } from "./AddAlphabet"
import React, { useContext, useState } from "react"
import { AlphabetItem } from "../../../../types"
import { makeStyles } from "@mui/styles"
import { Delete, ExpandMore, Language } from "@mui/icons-material"
import StatemachineContext from "../../../../context/statemachine.context"
import { ImportExportAlphabet } from "./ImportExportAlphabet"
import { EditAlphabet } from "./EditAlphabet"

const useStyles = makeStyles(
    (theme: Theme) => ({
        flex: {
            display: "flex",
            flexWrap: "wrap",
            "& > *": {
                margin: theme.spacing(0.5),
            },
        },
        red: {
            color: "red",
        },
    }),
    { name: "AlphabetContainer" }
)

export const AlphabetContainer = () => {
    const classes = useStyles()
    const { alphabet, setAlphabet, updateAlphabet } = useContext(StatemachineContext)

    const [openDelete, setOpenDelete] = useState<boolean>(false)
    const [currentIdToDelete, setCurrentIdToDelete] = useState<string>("")

    const handleAddAlphabetItem = (item: AlphabetItem) => {
        setAlphabet([...alphabet, item])
    }

    const handleEditAlphabetItem = (item: AlphabetItem) => {
        updateAlphabet(item)
    }

    const handleDeleteAlphabetItemClick = (id: string) => {
        setCurrentIdToDelete(id)
        setOpenDelete(!openDelete)
    }

    const handleDeleteDialogClose = () => {
        setCurrentIdToDelete("")
        setOpenDelete(!openDelete)
    }

    const handleDeleteAlphabetItem = () => {
        const index = alphabet.findIndex((item) => item.id === currentIdToDelete)
        alphabet.splice(index, 1)
        setAlphabet([...alphabet])
        handleDeleteDialogClose()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h5">Alexa Eingabe</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="end">
                        <ImportExportAlphabet />
                    </Grid>
                </Grid>
                <Typography variant="body2" marginBottom={1}>
                    Eingaben des Nutzers. Beispiel <i>"Gib mir die Hilfe"</i>
                </Typography>
                <AddAlphabet onAdd={handleAddAlphabetItem} />
            </Grid>
            <Grid item xs={12}>
                {alphabet.map((item) => (
                    <Accordion key={item.id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            {item.isGlobal && <Language />}
                            <Typography>&nbsp;&nbsp;{item.id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.flex}>
                                {item.synonyms.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button
                                className={classes.red}
                                startIcon={<Delete />}
                                onClick={() =>
                                    handleDeleteAlphabetItemClick(item.id)
                                }
                            >
                                Löschen
                            </Button>
                            <EditAlphabet
                                onEdit={handleEditAlphabetItem}
                                alphabet={item}
                            />
                        </AccordionActions>
                    </Accordion>
                ))}
            </Grid>
            <Dialog open={openDelete} onClose={handleDeleteDialogClose}>
                <DialogTitle>Nutzereingabe löschen</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du die Nutzereingabe "<b>{currentIdToDelete}</b>"{" "}
                        wirklich löschen?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Abbrechen</Button>
                    <Button
                        className={classes.red}
                        onClick={handleDeleteAlphabetItem}
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
