import {
    AlphabetItem,
    CSVImportData,
    EffectItem,
    StateItem,
    Transition,
} from "../types"
import { FlowElement } from "react-flow-renderer"
import { Node, Options } from "../types/statemachineOutput"
import JSZip from "jszip"

function transformCsvDataToAlphabet(csv: CSVImportData[]): AlphabetItem[] {
    const alphabet = [] as AlphabetItem[]

    if (!csv || csv.length < 1) {
        return []
    }

    csv.map((item) =>
        alphabet.push({ id: item.data[0], synonyms: item?.data?.slice(1) })
    )

    return alphabet
}

function transformAlphabetToCSVData(alphabet: AlphabetItem[]): string {
    let returnString = ""

    alphabet.map((item, index) => {
        item.synonyms.unshift(item.id)
        returnString +=
            item.synonyms.join() + `${index !== alphabet.length - 1 ? "\n" : ""}`
        return []
    })

    return returnString
}

function transformStateToDiagram(
    states: StateItem[],
    alphabet: AlphabetItem[]
): FlowElement<any>[] {
    const elements = [] as FlowElement<any>[]

    states.map((state) => {
        // create node
        elements.push({
            id: state.id,
            position: state.position || { x: 15, y: 15 },
            type: state.type || "default",
            style: {
                border: "1px solid transparent",
                borderRadius: 10,
                padding: 8,
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            },
            data: {
                label: state.id,
                stateAlphabet: state.allowedAlphabet,
            },
        })

        // create edges
        state.transitions.map((trans) =>
            elements.push({
                id: `${state.id}-${trans.target}`,
                source: state.id,
                sourceHandle: trans.sourceHandle,
                targetHandle: "input",
                target: trans.target || "",
                label: trans.condition
                    ? `${trans.effect} (if: ${trans.condition})`
                    : trans.effect,
                animated: trans.sourceHandle !== "fallback",
                type: `${
                    trans.sourceHandle === "fallback"
                        ? "straight"
                        : trans.sourceHandle === "effectNavigation"
                        ? "smoothstep"
                        : "smoothstep"
                }`,
                style: {
                    strokeWidth: 5,
                    stroke:
                        trans.sourceHandle === "fallback"
                            ? "red"
                            : trans.sourceHandle === "effectNavigation"
                            ? "orange"
                            : "green",
                },
            })
        )

        return []
    })

    console.log(elements)
    return elements
}

function transformToStateMachineOutput(
    states: StateItem[],
    alphabet: AlphabetItem[],
    globalTransitions: Transition[]
) {
    const alphabetObject: { [key in string]: string } = {}
    const statesObject: { [key in string]: Node } = {}
    const globalTransitionObject: {
        [key in string]: { to: string; effect: string }
    } = {}

    states.map((state) => {
        const transitions: any = {}

        state.transitions.map((trans) => {
            transitions[trans.sourceHandle] = {
                to: trans.target,
                effect: `__${trans.effect}__`,
            }
        })

        statesObject[state.id] = {
            // @ts-ignore
            transitions,
        }
    })

    alphabet.map((al) => {
        alphabetObject[al.id] = al.synonyms.join(",")
    })

    globalTransitions.map((trans) => {
        globalTransitionObject[trans.sourceHandle] = {
            to: trans.target || "",
            effect: trans.effect || "",
        }
    })

    const options: Options = {
        entryNode: "INITIAL",
        states: statesObject,
        alphabet: alphabetObject,
        globalTransitions: {
            transitions: globalTransitionObject,
        },
        // @ts-ignore
        effects: "__EFFECTS__",
    }

    console.log(options)
    return options
}

function isEffectUsed(effect: EffectItem, states: StateItem[]) {
    console.log("states", states)
    return (
        states.filter(
            (state) =>
                state.transitions.filter(
                    (transition) =>
                        transition.effect === effect.id &&
                        transition.sourceHandle !== "effectNavigation"
                ).length > 0
        ).length > 0
    )
}

function getTextFromEffect(effect: string, effects: EffectItem[]) {
    return effects.filter((eff) => eff.id === effect)?.[0]?.text
}

function getRepromptFromEffect(effect: string, effects: EffectItem[]) {
    return effects.filter((eff) => eff.id === effect)?.[0]?.reprompt
}

function getShouldEndSessionFromEffect(effect: string, effects: EffectItem[]) {
    return effects.filter((eff) => eff.id === effect)?.[0]?.shouldEndSession
}

function getTargetStatesInputEffectNavigation(
    effect: EffectItem,
    effects: EffectItem[],
    states: StateItem[]
) {
    let targetStates: string[] = []
    let sourceStatesInputEffects: {
        effect: string
        id: string
        condition: string
        text: string[]
        reprompt: string
        state: string
        shouldEndSession: boolean
    }[] = []

    states.filter(
        (state) =>
            state.transitions
                .filter((transition) => transition.effect === effect.id)
                .map((trans) => targetStates.push(trans.target || "")).length > 0
    )

    targetStates = [...new Set(targetStates)]

    targetStates.map((stateName) => {
        states.filter((state) =>
            state.transitions
                .filter(
                    (transition) =>
                        transition.source === stateName &&
                        transition.sourceHandle === "effectNavigation"
                )
                .map((trans) =>
                    sourceStatesInputEffects.push({
                        effect: trans.effect || "",
                        condition: trans.condition || "",
                        state: trans.target || "",
                        id: effect.id,
                        text: getTextFromEffect(trans.effect || "", effects),
                        reprompt:
                            getRepromptFromEffect(trans.effect || "", effects) || "",
                        shouldEndSession: getShouldEndSessionFromEffect(
                            trans.effect || "",
                            effects
                        ),
                    })
                )
        )
    })

    return sourceStatesInputEffects
}

function isTargetPlayAudioState(
    effect: EffectItem,
    effects: EffectItem[],
    states: StateItem[]
) {
    const targetStates: string[] = []

    states.filter(
        (state) =>
            state.transitions
                .filter((transition) => transition.target === "PLAY_AUDIO")
                .map((trans) => targetStates.push(trans.effect || "")).length > 0
    )

    return targetStates.filter((i) => i).indexOf(effect.id) >= 0
}

export function createEffectFiles(
    effects: EffectItem[],
    states: StateItem[],
    zip: JSZip
): void {
    let fileExports = ""

    effects
        .filter((i) => isEffectUsed(i, states))
        .map((effect) => {
            let effectData = ""
            let conditions = ""
            let shouldPlayAudio = ""

            const sourceStatesInputEffect = getTargetStatesInputEffectNavigation(
                effect,
                effects,
                states
            )

            if (isTargetPlayAudioState(effect, effects, states)) {
                shouldPlayAudio = `
            playMedia: context.media.currentMediaItem,`
            }

            if (sourceStatesInputEffect.length > 0) {
                sourceStatesInputEffect.map((cond) => {
                    conditions += `
        if (context.${cond.condition}) {
            return {
                ...context,
                say: SPEAK.${cond.id.toUpperCase()},
                reprompt: SPEAK.${cond.id.toUpperCase() + "_REPROMPT"},
                effectNavigation: "${cond.state}",
                withShouldEndSession: ${cond.shouldEndSession},
                apl: await getDefaultTemplate(SPEAK.${cond.id.toUpperCase()}),
            }
       }
                    `
                })
            }

            if (effect.text[0].startsWith("http")) {
                effect.text[0] = `<audio src='${effect.id}' />`
            }

            effectData += `
// This file is generated by hilbert. You can edit and use it in your skill.
// https://cloudservices.wdr.de/voice/hilbert        

import { UserReducerTargets } from "@westdeutscherrundfunkkoeln/hilbert"    
import { useReducerFromHandler } from "../../../handler"
import { getDefaultTemplate } from "../../util/aplUtil"    
import SPEAK from "../../config/speak"
            
/* ${effect.comment || "No user comment - describe what happens here"} */ 
export const ${effect.id} = async (context: any) => {
        ${conditions}
    return {
        ...context,
        say: SPEAK.${effect.id.toUpperCase()},${shouldPlayAudio}
        reprompt: SPEAK.${effect.id.toUpperCase() + "_REPROMPT" || ""},
        withShouldEndSession: ${effect.shouldEndSession},
        apl: await getDefaultTemplate(SPEAK.${
            effect.text[0].startsWith("<audio")
                ? effect.id.toUpperCase()
                : effect.id.toUpperCase()
        }),
    }
}
        `

            const blob = new Blob([effectData], { type: "text/plain" })
            zip.file(`statemachine/effects/${effect.id}.ts`, blob)

            fileExports += `export { ${effect.id} } from "./${effect.id}"\n`
            return effectData
        })

    const indexBlob = new Blob([fileExports], { type: "text/plain" })
    zip.file(`statemachine/effects/index.ts`, indexBlob)
}

function transformSpeakOutput(effects: EffectItem[]) {
    let returnString = ""

    effects.map((effect) => {
        returnString += `
export const ${effect.id.toUpperCase()}: string = "${effect.text
            ?.toString()
            .replaceAll('"', "'")}"
export const ${effect.id.toUpperCase() + "_REPROMPT"}: string = "${
            effect.reprompt?.toString().replaceAll('"', "'") || ""
        }"
        `
    })

    returnString += `
export default {
    ${effects?.map((e) => e.id.toUpperCase()).join(", ")}, ${effects
        ?.map((e) => e.id.toUpperCase() + "_REPROMPT")
        .join(", ")}
}    
    `

    return returnString
}

export function createStatemachineConfigFile(
    options: any,
    effects: EffectItem[],
    states: StateItem[],
    zip: JSZip
) {
    const effectIds = effects
        .filter((i) => isEffectUsed(i, states))
        .map((effect) => effect.id)

    let fileData = `// This file is generated by hilbert. You can edit and use it in your skill.
// https://cloudservices.wdr.de/voice/hilbert        

import { Options, useStateMachine } from "@westdeutscherrundfunkkoeln/hilbert/dist/statemachine"
import { ${effectIds.join(", ")} } from "./effects" 
        
const options: Options = ${JSON.stringify(options)}
        
const uninitializedStateMachine = useStateMachine(options)

function Statemachine(currentState?: any) {
    return uninitializedStateMachine(currentState)
}
        
export { Statemachine } 
        `

    effectIds.map((id) => {
        fileData = fileData.replaceAll(`"__${id}__"`, `${id}`)
    })

    fileData = fileData.replace('"__EFFECTS__"', "{}")
    const blob = new Blob([fileData], { type: "text/plain" })
    zip.file(`statemachine/statemachine.ts`, blob)
}

export function createReadmeFile(zip: JSZip) {
    const content = `
# Created statemachine configuration by Hilbert    
    `

    const blob = new Blob([content], { type: "text/plain" })
    zip.file(`readme.md`, blob)
}

export function createInteractionModel(alphabet: AlphabetItem[], zip: JSZip) {
    const content = `
{
  "interactionModel": {
    "languageModel": {
      "invocationName": "__INVOCATION_NAME__",
      "modelConfiguration": {
        "fallbackIntentSensitivity": {
          "level": "MEDIUM"
        }
      },
      "intents": [
        {
          "name": "DecisionIntent",
          "slots": [
            {
              "name": "decision",
              "type": "decision"
            }
          ],
          "samples": [
            "{decision}"
          ]
        },
        {
          "name": "AMAZON.CancelIntent",
          "samples": []
        },
        {
          "name": "AMAZON.HelpIntent",
          "samples": []
        },
        {
          "name": "AMAZON.StopIntent",
          "samples": []
        },
        {
          "name": "AMAZON.FallbackIntent",
          "samples": []
        },
        {
          "name": "AMAZON.LoopOffIntent",
          "samples": []
        },
        {
          "name": "AMAZON.LoopOnIntent",
          "samples": []
        },
        {
          "name": "AMAZON.ShuffleOffIntent",
          "samples": []
        },
        {
          "name": "AMAZON.ShuffleOnIntent",
          "samples": []
        },
        {
          "name": "AMAZON.YesIntent",
          "samples": []
        },
        {
          "name": "AMAZON.NoIntent",
          "samples": []
        },
        {
          "name": "AMAZON.PauseIntent",
          "samples": []
        },
        {
          "name": "AMAZON.ResumeIntent",
          "samples": []
        },
        {
          "name": "AMAZON.NavigateHomeIntent",
          "samples": []
        }
      ],
      "types": [
        {
          "name": "decision",
          "values": [
            ${alphabet
                .map(
                    (a) =>
                        `{"name": {"value": "${a.id}", "synonyms": [${a.synonyms
                            .map((s) => `"${s.replaceAll("_", " ").toLowerCase()}"`)
                            .join(", ")}]}}`
                )
                .join(",")}
          ]
        }
      ]
    }
  }
}   
    `

    const blob = new Blob([content], { type: "text/plain" })
    zip.file(`de-DE.json`, blob)
}

export {
    transformCsvDataToAlphabet,
    transformAlphabetToCSVData,
    transformStateToDiagram,
    transformToStateMachineOutput,
    transformSpeakOutput,
}
