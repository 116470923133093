import { AlphabetTemplates } from "../types"

export const TEMPLATES: AlphabetTemplates = {
    yes: ["ja", "yes", "ok", "ja mach"],
    no: ["nein", "no", "nope", "lieber nicht"],
    help: [
        "hilfe",
        "gib mir die hilfe",
        "öffne die hilfe",
        "ich komme nicht weiter",
        "was muss ich machen",
    ],
    forward: ["weiter", "der nächste", "das nächste", "nächster", "einen vor"],
    backward: ["zurück", "der letzte", "das letzte", "letzter", "einen zurück"],
}
