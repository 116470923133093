import { StatesContainer } from "./components/StatesContainer"
import { AlphabetContainer } from "./components/alphabet/AlphabetContainer"
import {
    Grid,
    Theme,
    Alert,
    Button,
    Link,
    Card,
    CardContent,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { EffectsContainer } from "./components/effects/EffectsContainer"
import React, { useContext, useEffect, useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton"
import {
    authenticateUser,
    createSkill,
    LOGIN_URL,
} from "../../services/rest.service"
import StatemachineContext from "../../context/statemachine.context"
import MessageContext from "../../context/MessageContext"

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            padding: theme.spacing(2),
        },
        info: {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#2196f3",
        },
        loginButton: {
            margin: "auto",
            marginTop: "10%",
            width: 400,
            textAlign: "center",
        },
        actionContainer: {
            textAlign: "right",
        },
    }),
    { name: "DashboardContainer" }
)

export const DashboardContainer = () => {
    const classes = useStyles()

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [createSkillModal, setOpenCreateSkillModal] = useState(false)
    const { states, alphabet, effects } = useContext(StatemachineContext)
    const { setMessage } = useContext(MessageContext)

    useEffect(() => {
        async function fetchUser() {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const code = urlParams.get("code")

            urlParams.delete("code")

            if (!code) {
                return
            }

            setLoading(true)
            const response: any = await authenticateUser(code)

            if (response?.id_token) {
                localStorage.setItem("token", response.id_token)
                setUser(response)
            } else {
                setUser(null)
            }

            setLoading(false)
        }

        fetchUser()
    }, [])

    const handleCreateSkillClick = () => {
        setOpenCreateSkillModal(!createSkillModal)
    }

    const handleCreateSkill = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        // @ts-ignore
        const data = new FormData(e.target)

        await createSkill(
            // @ts-ignore
            data.get("name"),
            data.get("invocation"),
            data.get("email"),
            states,
            alphabet,
            effects
        )

        handleCreateSkillClick()

        setMessage({
            open: true,
            type: "info",
            content: "Dein Alexa Skill wird in ungefähr 6 Minuten bereit sein.",
        })
    }

    if (user) {
        return (
            <Card className={classes.loginButton}>
                <CardContent>
                    <Typography variant="h6">
                        Melde dich für den Zugriff auf Hilbert und deine erstellten
                        Alexa Skills an.
                    </Typography>
                    <br />
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        color="primary"
                        component={Link}
                        href={LOGIN_URL}
                    >
                        Anmelden / Registrieren
                    </LoadingButton>
                </CardContent>
            </Card>
        )
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} md={9}>
                <StatesContainer />
            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent id="effect-container">
                                <EffectsContainer />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent id="alphabet-container">
                                <AlphabetContainer />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.actionContainer}>
                <Button
                    onClick={handleCreateSkillClick}
                    variant="contained"
                    disabled
                >
                    Alexa Skill erstellen
                </Button>
                <Dialog open={createSkillModal} onClose={handleCreateSkillClick}>
                    <DialogTitle>Alexa Skill erstellen</DialogTitle>
                    <form onSubmit={handleCreateSkill}>
                        <DialogContent>
                            <Typography>
                                Bist du sicher, dass du deine Konfiguration
                                abgeschlossen hast?
                                <br />
                                Eine nachträgliche Änderung ist nicht mehr möglich.
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        label="Name des Skills"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="invocation"
                                        label="Invocation"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        label="E-Mail Adresse (Beta-Test)"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCreateSkillClick}>
                                Abbrechen
                            </Button>
                            <Button color="primary" type="submit">
                                Erstellen
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Grid>
        </Grid>
    )
}
